import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const ChomskySunni = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Chomsky's Sunni" />
        <meta name="twitter:image" content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png" />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div className="article">
        <Link to="/shoyaright">
        <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p> Chomsky's Sunni </p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Feb. 12th, 2020) </p>
        </div>
        <div className="articleBody">
        <p>
        As the 2020 primaries begin, you might find yourself laughing at Buttigieg or Klobuchar or anybody from Iowa. Ha-ha.
        </p>
        <p>
        But if you're taking these elections very super seriously -- as I am -- you might be looking for thoughtful and pointed remarks about the possible outcomes in American "democracy".
        </p>
        <p>
        You might find yourself seeking out commentary from legendary philosopher and activist Noam Chomsky on the (in)significance of the impeachment trials, or the prospects of a Bernie Sanders presidency -- at which point, you're likely to discover a fact that's far more exciting, far more consequential than who receives the Democratic nomination.
        </p>
        <p>
        That fact is: Noam Chomsky is growing out his Sunni.
        </p>
        <p>
        If you're not a Black person from Philadelphia, {' '}
        <a href="https://www.youtube.com/watch?time_continue=87&v=BFiVuucg1kM&feature=emb_title">
        accept this gift</a>,
        for context (courtesy of WHYY, s/o my boo{' '}<a href="https://www.thedailybeast.com/adam-driver-walks-out-of-npr-fresh-air-interview-over-marriage-story-clip">Terry Gross</a>).
        </p>
        <p>
        I don't think I need to illustrate the gravity of this development (not that I'd know how), but it makes me wonder: what might be the ramifications here? What does it bode that Chomsky is finally putting his facial follicles to good use?
        </p>
        <p>
        Prior to this occasion, Chomsky easily ranked among the top-ten greatest Philadelphians who have ever lived. Now, I can say without hesitation that he has planted himself firmly in the top-five.         </p>
        <p>
        Who knows how many copies of <i>Manufacturing Consent</i> they would have sold had he been letting his shit rock from the beginning?
        </p>
        <p>
        Be it a good or a bad omen, all I know is that somebody better get my dog some Amber White right now.
        </p>
        </div>
        <p className="plug">If you enjoyed this article, consider <a href="https://www.patreon.com/join/shoyaright?">becoming a patron</a> or making a <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">one-time pledge</a>. Support corporate journalism!</p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020</p>
        </div>
      </div>
    </div>

  );
};
export default ChomskySunni;
